import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

export type Offer = {
  id: string
  title: string,
  description: string,
  image: {
    url: string
  }
}

type Data = {
  elizabeth: {
    offers: [
      Offer
    ]
  }
}

const SwiperCarousel = () => {
  const data = useStaticQuery<Data>(graphql`
    query ElizabethOfferQuery {
      elizabeth {
        offers {
          id
          title
          description
          image {
            url
          }
        }
      }
    }
  `)

  const offers = data.elizabeth.offers;
  
  return (
    <>
      <StyledSwiper
        modules={[Navigation, Pagination]}
        spaceBetween={50}
        slidesPerView={1.5}
        speed={600}
        centeredSlides={true}
        loop={true}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
          type: 'fraction'
        }}
        breakpoints={{
          1200: {
            spaceBetween: 200,
            slidesPerView: 2.8
          }
        }}
      >

      {
        offers.map(({id, title, description, image}) => (
            <SwiperSlide key={id}>
              <h2>{title}</h2>
              <p>{description}</p>
              {
                image ? ( <img loading='lazy' src={image.url} alt={title}/> ) : <></>  
              }
            </SwiperSlide>
          )
        )
      }
      </StyledSwiper>

      <SwiperButton position='right'>
        <div className='swiper-button-next' /> 
      </SwiperButton>
      <SwiperButton position='left'>
        <div className='swiper-button-prev' /> 
      </SwiperButton>

      <SwiperPagination className='swiper-pagination'/>

    </>
  )
}

export default SwiperCarousel;

const StyledSwiper = styled(Swiper)`
  text-align: center;
  margin: 50px auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  .swiper-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${({theme}) => theme.colors.gray};
    position: relative;
    border-radius: 20px;
    height: auto;
    z-index: 999;
    transition: background-color .6s .1s ease-in-out, color .6s .1s ease-in-out;
    text-align: center;
    

    h2 {
      margin-top: 30px;
      padding: 0 20px;
      @media screen and (max-width: 768px) {
        font-size: ${(({ theme }) => theme.font.size.s)};
      }
    }

    p {
      font-size: 1.7rem;
      line-height: 150%;
      padding: 0 4%;
      margin: 30px 0 10% 0;

      @media screen and (max-width: 768px) {
        font-size: 1.4rem;
        margin: 10px 0;
      }
    }

    img {
      border-radius: 0 0 20px 20px;
      width: 100%;
      height: 300px;
      object-fit: cover;

      @media screen and (max-width: 768px), (max-height: 700px) {
        height: 150px;
      }
    }
  }

  .swiper-slide-active {
      background-color: #D64ACE;
      color: #fff;
  }
`

type SwiperButtonProps = {
  position: string,
}


const SwiperButton = styled.button<SwiperButtonProps>`
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  outline: none;
  border: none;
  background: transparent;
  z-index: 999;
  bottom: 50%;
  ${(( { position }) => position === 'right' ? 'right: 30%' : 'left: 30%')};

  @media screen and (max-width: 1300px) {
    cursor: none;
    bottom : -20px;
    ${(( { position }) => position === 'right' ? 'right: 10%' : 'left: 10%')};
  }

  .swiper-button-next,
  .swiper-button-prev {
    padding: 30px;
    color: ${(({ theme }) => theme.colors.text)};
  }
`

export const SwiperPagination = styled.div`
  position: absolute;
  bottom: 0;
  z-index: -1;
`


